<template>
  <Div :darkMode="darkMode">
    <Layout class="layout">
      <Header
        :style="{
          position: 'fixed',
          width: '100%',
          top: 0,
          [!rtl ? 'left' : 'right']: 0,
        }"
      >
        <a-row>
          <a-col :lg="!topMenu ? 1 : 1" :sm="1" :xs="12" class="align-center-v navbar-brand">
            <sdButton v-if="!topMenu || innerWidth <= 991" @click="toggleCollapsed" type="white">
              <img :src="require(`@/assets/img/icon/${collapsed ? 'right.svg' : 'left.svg'}`)" alt="menu" />
            </sdButton>
            <router-link :class="topMenu && innerWidth > 991 ? 'striking-logo top-menu' : 'striking-logo'" to="/">
              <img
                :src="!darkMode ? require(`@/assets/img/Gamii-icon.png`) : require(`@/assets/img/Gamii-icon.png`)"
                alt="logo" style="max-width: 50px;margin-left: 0px;"
              />
            </router-link>
          </a-col>
          <a-col :lg="!topMenu ? 17 : 17" :md="8" :sm="0" :xs="0">
            <TopMenu v-if="topMenu && innerWidth > 991" />
            <HeaderSearch v-else />
          </a-col>

          <a-col :lg="6" :md="10" :sm="0" :xs="0">
            <TopMenuSearch v-if="topMenu && innerWidth > 991">
              <div class="top-right-wrap d-flex">
                <a
                  :class="`${activeSearch ? 'search-toggle active' : 'search-toggle'}`"
                  @click="
                    () => {
                      toggleSearch();
                    }
                  "
                  href="#"
                >
                  <sdFeatherIcons type="search" />
                  <sdFeatherIcons type="x" />
                </a>
                <div :class="`${activeSearch ? 'topMenu-search-form show' : 'topMenu-search-form'}`">
                  <form action="">
                    <span class="search-icon">
                      <sdFeatherIcons type="search" />
                    </span>
                    <input type="text" name="search" />
                  </form>
                </div>
                <AuthInfo />
              </div>
            </TopMenuSearch>
            <!-- <AuthInfo v-else /> -->
          </a-col>
        </a-row>
      </Header>
      <div class="header-more">
        <a-row>
          <a-col :md="0" :sm="24" :xs="24">
            <div class="small-screen-headerRight">
              <SmallScreenSearch :hide="searchHide" :darkMode="darkMode">
                <!-- <HeaderSearch /> -->
              </SmallScreenSearch>
              <SmallScreenAuthInfo :hide="hide" :darkMode="darkMode">
                <AuthInfo :rtl="rtl" />
              </SmallScreenAuthInfo>
            </div>
          </a-col>
        </a-row>
      </div>
      <Layout>
        <template v-if="!topMenu || innerWidth <= 991">
          <Sider
            :width="280"
            :style="{
              textAlign : 'center',
              padding: '15px 15px 155px 15px',
              overflowY: 'auto',
              height: '100vh',
              position: 'fixed',
              [!rtl ? 'left' : 'right']: 0,
              zIndex: 998,
            }"
            :collapsed="collapsed"
            :theme="!darkMode ? 'light' : 'dark'"
          >
            <router-link :class="topMenu && innerWidth > 991 ? 'striking-logo top-menu' : 'striking-logo'" to="/">
              <img
                :src="!darkMode ? require(`@/assets/img/Gamii-icon.png`) : require(`@/assets/img/Gamii-icon.png`)"
                alt="logo" style="max-width: 50px;margin-left: 0px;"
              />
            </router-link>
            <perfect-scrollbar
              :options="{
                wheelSpeed: 1,
                swipeEasing: true,
                suppressScrollX: true,
              }"
            >
              <AsideItems
                :toggleCollapsed="toggleCollapsedMobile"
                :topMenu="topMenu"
                :rtl="rtl"
                :darkMode="darkMode"
                :events="onEventChange"
              />
              <div class="sidebar-bottom-view">
                <Notification />
                <a-popover
                  trigger="click"
                  :visible="hovered"
                  placement="rightBottom"
                  @visibleChange="handleHoverChange"
                >
                  <template #content>
                    <UserDropDwon>
                      <div class="user-dropdwon">
                        <ul class="user-dropdwon__links">
                          <li>
                            <a @click="navigateTo(`/${initial}/settings`)"> <sdFeatherIcons type="settings" /> Settings </a>
                          </li>
                        </ul>
                        <a @click="SignOut" class="user-dropdwon__bottomAction" href="#"> <LogoutOutlined /> Sign Out </a>
                      </div>
                    </UserDropDwon>
                  </template>
                  <a to="#" class="head-example">
                    <a-avatar size="large" :src="authUserAvatar">
                      <template #icon>
                        <sdFeatherIcons type="user" size="20" />
                      </template>
                    </a-avatar>
                  </a>
                </a-popover>
              </div>
            </perfect-scrollbar>
          </Sider>
        </template>
        <Layout class="atbd-main-layout">
          <Content>
            <Suspense>
              <template #default>
                <router-view></router-view>
              </template>
              <template #fallback>
                <div class="spin">
                  <a-spin />
                </div>
              </template>
            </Suspense>

            <Footer
              class="admin-footer"
              :style="{
                padding: '20px 30px 18px',
                color: 'rgba(0, 0, 0, 0.65)',
                fontSize: '14px',
                background: 'rgba(255, 255, 255, .90)',
                width: '100%',
                boxShadow: '0 -5px 10px rgba(146,153,184, 0.05)',
              }"
            >
              <a-row>
                <a-col :md="12" :xs="24">
                  <span class="admin-footer__copyright">{{new Date().getFullYear()}} © Gamii</span>
                </a-col>
                <!-- <a-col :md="12" :xs="24">
                  <div class="admin-footer__links">
                    <router-link to="/app/social/profile/overview">Profile</router-link>
                    <router-link to="/app/users/team">Team</router-link>
                    <router-link to="/app/contact/contact-grid">Contacts</router-link>
                  </div>
                </a-col> -->
              </a-row>
            </Footer>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  </Div>
</template>
<script>
import { Layout } from 'ant-design-vue';
import { LogoutOutlined } from '@ant-design/icons-vue';
import { Div, SmallScreenSearch, SmallScreenAuthInfo, TopMenuSearch, UserDropDwon } from './style';
import HeaderSearch from '../../components/header-search/HeaderSearch';
import AuthInfo from '../../components/utilities/auth-info/info';
import Notification from "../../components/utilities/auth-info/Notification.vue";
import AsideItems from './Aside';
import TopMenu from './TopMenuItems';
import { PerfectScrollbar } from 'vue3-perfect-scrollbar';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
const { Header, Footer, Sider, Content } = Layout;

export default {
  name: 'WithAdminLayout',
  components: {
    Div,
    Header,
    Layout,
    Footer,
    Sider,
    Content,
    HeaderSearch,
    SmallScreenSearch,
    SmallScreenAuthInfo,
    TopMenuSearch,
    AuthInfo,
    AsideItems,
    TopMenu,
    PerfectScrollbar,
    UserDropDwon,
    Notification,
    LogoutOutlined
  },
  computed: {
    authUserName: function() {
      String.prototype.capitalize = function() {
        return this.charAt(0).toUpperCase() + this.slice(1);
      }
      const name = this.$store?.getters?.authUser?.name ?? "";
      return name.capitalize();
    },
    authUserAvatar: function() {
      return this.$store?.getters?.authUser?.avatar ?? "";
    },
    initial: function() {
      return this.$store?.getters?.initial;
    }
  },
  setup() {
    const collapsed = ref(false);
    const hide = ref(true);
    const hovered = ref(false);
    const searchHide = ref(true);
    const customizerAction = ref(false);
    const activeSearch = ref(false);

    // const store = useStore();
    const { replace } = useRouter();
    const { dispatch, state } = useStore();
    dispatch('getCountries');
    const rtl = computed(() => state.themeLayout.rtlData);
    const darkMode = computed(() => state.themeLayout.data);
    const topMenu = computed(() => state.themeLayout.topMenu);

    // collapsed.value = window.innerWidth <= 1200 && true;
    collapsed.value = true;

    const toggleCollapsed = e => {
      e.preventDefault();
      collapsed.value = !collapsed.value;
    };
    const handleSearchHide = search => {
      searchHide.value = !search;
      hide.value = true;
    };
    const onShowHide = h => {
      hide.value = !h;
      searchHide.value = true;
    };
    const toggleSearch = () => {
      activeSearch.value = !activeSearch.value;
    };

    const toggleCollapsedMobile = () => {
      if (innerWidth <= 990) {
        collapsed.value = !collapsed.value;
      }
    };
    
    const handleHoverChange = visible => {
      hovered.value = visible;
    };

    const onRtlChange = () => {
      const html = document.querySelector('html');
      html.setAttribute('dir', 'rtl');
      dispatch('changeRtlMode', true);
    };

    const onLtrChange = () => {
      const html = document.querySelector('html');
      html.setAttribute('dir', 'ltr');
      dispatch('changeRtlMode', false);
    };

    const modeChangeDark = () => {
      dispatch('changeLayoutMode', true);
    };

    const modeChangeLight = () => {
      dispatch('changeLayoutMode', false);
    };

    const modeChangeTopNav = () => {
      dispatch('changeMenuMode', true);
    };

    const modeChangeSideNav = () => {
      dispatch('changeMenuMode', false);
    };
    const navigateTo = (path = "") => {
      if (path) {
        replace({path});
      }
      hovered.value = false;
      toggleCollapsedMobile()
    };
    const SignOut = () => {
      hovered.value = false;
      replace('/portal/login');
      dispatch('logOut');
    };

    const onEventChange = {
      onRtlChange,
      onLtrChange,
      modeChangeDark,
      modeChangeLight,
      modeChangeTopNav,
      modeChangeSideNav,
    };
    return {
      toggleCollapsed,
      handleSearchHide,
      toggleCollapsedMobile,
      onShowHide,
      collapsed,
      hide,
      searchHide,
      toggleSearch,
      customizerAction,
      activeSearch,
      innerWidth: window.innerWidth,
      rtl,
      darkMode,
      topMenu,
      onEventChange,
      SignOut,
      navigateTo,
      hovered,
      handleHoverChange
    };
  },
};
</script>
<style>
.ps {
  height: calc(100vh - 100px);
}
.welcome_text {
  margin: 0px 0px 0px 10px; 
  font-size: 15px;
  padding-left: 15px;
}
.welcome_text b {
  color: #db315a;
}
</style>
